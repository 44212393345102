import type { ModalFields } from '@/types/ShopFront/CheckoutModal'

export const pickUpModal: ModalFields = [
  {
    type: 'title',
    content: 'PICK UP IN STORE DETAILS'
  },
  {
    type: 'paragraph',
    content: [
      'How it works:',
      '1.Once we have processed your order, you will receive an email that your purchase is ready for pickup.',
      '2.When picking up your order, please bring your ID and email confirmation.',
      '3.Orders not picked up within 7 days will be canceled.',
    ]
  },
  {
    type: 'paragraph',
    content: [
      'Which items are eligible for pick - up in -store ?',
      'Most of our products are eligible for in -store pick- up, except for furniture and items that are shipped directly from our vendors.  ',
    ]
  },
  {
    type: 'paragraph',
    content: [
      'When will my order be ready ?',
      'You will receive a "ready to pick-up" email confirmation when your items are ready for pick-up.  ',
    ]
  }, {
    type: 'paragraph',
    content: [
      'What do I need to pick-up my order?',
      'Always wait for your "ready to pick-up" email confirmation before picking up your order. When you arrive at your pick-up store, please notify an Associate. You will need your government-issued ID, the credit card used for the purchase, and your "ready to pick-up" email confirmation.  '
    ]
  }, {
    type: 'paragraph',
    content: [
      'Can someone else pick up my order?',
      'Contact the store to provide the name of the person picking up your order. That person must present the "ready to pick-up" email confirmation and have a valid photo ID. '
    ]
  }, {
    type: 'paragraph',
    content: [
      'When will I be charged for my order?',
      'Your payment is processed when your order is placed online.  '
    ]
  }, {
    type: 'paragraph',
    content: [
      'What happens if I do not pick-up my order?',
      'If you do not pick-up your order within 7 days of receiving your "ready to pick-up" email confirmation your order will be canceled.  '
    ]
  }, {
    type: 'paragraph',
    content: [
      'How do I cancel my order? ',
      'Contact the store where you have requested a pick-up and let them know that you would like to cancel your order.  '
    ]
  }, {
    type: 'paragraph',
    content: [
      'Can I have my order gift wrapped?',
      'Yes, gift wrap options are available at your pick-up store. '
    ]
  }, {
    type: 'paragraph',
    content: [
      'What should I do if I do not receive an email confirmation? ',
      'If you do not receive a "ready to pick-up" email confirmation within 2 hours, please contact Z Gallerie Customer Service by calling 424.999.4626.  '
    ]
  }, {
    type: 'paragraph',
    content: [
      'What if my pick-up store no longer has an item on my order?',
      'If items from your in-store pick-up order become unavailable, your pick-up store will contact you to let you know your options. '
    ]
  },
]
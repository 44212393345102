import type { ModalFields } from '@/types/ShopFront/CheckoutModal'

import {
  pickUpModal,
  furnitureModal,
  accessoryModal,
} from './modals'

export type ShippingOptionsDescriptionType = {
  title: string,
  description?: string,
  isUpgrade: boolean,
  showModalButton: boolean,
  modal: ModalFields,
}

export type ShippingOptionsWordingDescriptionMapType = {
  [key: string]: ShippingOptionsDescriptionType | undefined
}

export type ShippingOptionsWordingType = {
  isBopus: ShippingOptionsWordingDescriptionMapType,
  isFurniture: ShippingOptionsWordingDescriptionMapType,
  accessory: ShippingOptionsWordingDescriptionMapType,
}


export const fallbackShippingOptionsDescription: ShippingOptionsDescriptionType = {
  title: 'Standard Parcel Shipping',
  isUpgrade: false,
  showModalButton: true,
  modal: accessoryModal,
}

export const shippingOptionsWording: ShippingOptionsWordingType = {
  isBopus: {
    'Free Shipping':{
      title: "Pick Up In Store",
      isUpgrade: false,
      showModalButton: true,
      modal: pickUpModal,
    },
    "Zgallerie (BOPS)": {
      title: "Pick Up In Store",
      isUpgrade: false,
      showModalButton: true,
      modal: pickUpModal,
    },
  },
  isFurniture: {
    'Free Shipping':{
      title: "Free Shipping",
      isUpgrade: false,
      showModalButton: false,
      modal: furnitureModal,
    },
    "Zgallerie (Standard)": {
      title: "Furniture Delivery Charge",
      isUpgrade: false,
      showModalButton: true,
      modal: furnitureModal
    },
  },
  accessory: {
    'Free Shipping':{
      title: "Free Shipping",
      isUpgrade: false,
      showModalButton: false,
      modal: accessoryModal,
    },
    "Zgallerie (Standard)": {
      title: 'Standard Shipping',
      isUpgrade: false,
      showModalButton: true,
      modal: accessoryModal,
    },
    "Zgallerie (Standard Plus)": {
      title: 'Get it faster with Standard Plus',
      isUpgrade: true,
      showModalButton: true,
      modal: accessoryModal,
    }
  }
}

export const orderConfirmationShippingOptionsWording = {
  ...shippingOptionsWording,
  accessory: {
    'Free Shipping':{
      title: "Free Shipping",
      isUpgrade: false,
      showModalButton: false,
      modal: accessoryModal,
    },
    "Zgallerie (Standard)": {
      title: 'Standard Shipping',
      isUpgrade: false,
      showModalButton: true,
      modal: accessoryModal,
    },
    "Zgallerie (Standard Plus)": {
      title: 'Standard Plus',
      isUpgrade: true,
      showModalButton: true,
      modal: accessoryModal,
    },
  }
}

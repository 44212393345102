export const shipmentsWording = {
  isBopus: {
    shipmentTitle: 'PICK UP IN STORE',
    showShipmentTitleOnCartEditModal: false,
    timeline: 'Pick Up In Store',
    cartEditTitle: 'ITEMS BEING PICKED UP',
    showCartEditTitleOnCartEditModal: true,
    showAddressOnCartEditModal: true,
    showAddress:true,
  },
  isFurniture: {
    shipmentTitle: 'FURNITURE SHIPPING',
    showShipmentTitleOnCartEditModal: true,
    timeline: 'Furniture Delivery Charge',
    cartEditTitle: 'ITEMS IN YOUR CART',
    showCartEditTitleOnCartEditModal: false,
    showAddressOnCartEditModal: false,
    showAddress:false,
  },
  accessory: {
    shipmentTitle: 'ACCESSORY SHIPPING',
    showShipmentTitleOnCartEditModal: true,
    timeline: 'Standard Shipping',
    cartEditTitle: 'ITEMS IN YOUR CART',
    showCartEditTitleOnCartEditModal: false,
    showAddressOnCartEditModal: false,
    showAddress:false,
  }
}
import roundToNDigits from '@/helpers/roundToNDigits'
import type { StandardLineItem } from '@/types/ShopFront/CheckoutStandards'

export type DiscountGetter = (lineItems: Array<{
  discounts: ({ discountedAmount: number }[]) | { coupon: number } | undefined,
  originalPrice: number,
  salePrice: number,
  quantity: number,

}>) => number

export const calculateTotalDiscountFromLineItems
: DiscountGetter = (lineItems: StandardLineItem[]) => {
  try {
    return roundToNDigits(lineItems.reduce((sum, item) => {
      const { discounts } = item
      const couponDiscountTotal = discounts?.reduce?.((couponSum, coupon) => (
        couponSum + coupon?.discountedAmount
      ), 0) || 0

      const saleDiscountTotal = (item.originalPrice - item.salePrice) * item.quantity
      return sum + couponDiscountTotal + saleDiscountTotal
    }, 0))
  } catch (error) {
    console.error('Error in calculating total discount, returning undefined. Error:', error)
    return 0
  }
}

export default calculateTotalDiscountFromLineItems

import { BOPUS_SHIPPING_DESCRIPTION } from '@/data/constants'
import { BOPUS_FIELD } from '@/services/Configuration'

type MinimalPropsToMapConsignmentIsBopus = {
  availableShippingOptions: readonly {
    description: string
  }[]
  shippingAddress: {
    address2?: string
    formFields?: readonly {
      fieldId: string
      fieldValue: string
    }[]
    customFields?: readonly {
      fieldId: string
      fieldValue: string
    }[]
  }
}

type IsBopusMapper = <T extends MinimalPropsToMapConsignmentIsBopus>(consignment: T) => T & {
  isBopus: boolean
}

const isBopisCustomField = (field: { fieldId: string, fieldValue: string }) => (
  field.fieldId === BOPUS_FIELD && field.fieldValue === '1'
)

export const mapConsignmentIsBopus: IsBopusMapper = (consignment) => {
  const hasTrailingUnderscore = !!(consignment?.shippingAddress?.address2 || '').match(/_$/gi)
  const hasBopusShippingOption = consignment.availableShippingOptions.some((opt) => (
    opt.description === BOPUS_SHIPPING_DESCRIPTION
  ))
  const hasBopusCustomField = !!(
    consignment.shippingAddress?.formFields
    || consignment.shippingAddress?.customFields
  )?.find(isBopisCustomField)

  const isBopus = (hasTrailingUnderscore || hasBopusShippingOption || hasBopusCustomField)

  return { ...consignment, isBopus }
}

export default mapConsignmentIsBopus

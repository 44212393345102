import type { ModalFields } from '@/types/ShopFront/CheckoutModal'

export const furnitureModal: ModalFields = [
  {
    type: 'title',
    content: 'FURNITURE SHIPPING DETAILS'
  },
  {
    type: 'paragraph',
    content: [
      'FURNITURE SHIPPING',
      'Z Gallerie has four different shipping methods for delivering furniture. The methods employed depends on the distance from a Z Gallerie store, delivery zip code, and the type of furniture. Not all options will be available for all orders. All furniture deliveries require a signature at the time of delivery. It is the customer\'s responsibility to measure and ensure that the purchased items will fit through all doorways, halls, stairways, elevators, or any other passageway. View our guidelines on measuring for proper fit of any furniture. None of the delivery services offer a hoisting service.',
    ]
  },
  {
    type: 'paragraph',
    content: [
      'QUESTIONS?',
      'Please feel welcome to contact service at (424) 999-4626 between 9am to 5pm CST for any questions relating to your order.',
    ]
  },
]
import type { StandardAddress } from '@/types/ShopFront/CheckoutStandards'

type AddressProps = Omit<StandardAddress,
'addressType'
| 'street1'
| 'street2'
| 'stateName'
| 'stateAcronym'
| 'zip'
| 'shouldSaveAddress'
| 'city'
| 'country'
| 'countryCode'
| 'company'
| 'customFields'
| 'firstName'
| 'lastName'
| 'phone'
| 'email'
> & {
  email?: string,
  firstName?: string,
  lastName?: string,
  shouldSaveAddress?: boolean,
  city?: string,
  company?: string,
  country?: string,
  countryCode?: string,
  customFields?: readonly {
    fieldId: string,
    fieldValue: string,
  }[],
  phone?: string,
} & (
  {
    address1?: string
    address2?: string
    stateOrProvince?: string
    stateOrProvinceCode?: string
    postalCode?: string
    stateAcronym?: undefined,
    stateName?: undefined,
    street1?: undefined,
    street2?: undefined,
    zip?: undefined,
  }
  | {
    address1?: undefined
    address2?: undefined
    stateOrProvince?: undefined
    stateOrProvinceCode?: undefined
    postalCode?: undefined
    stateAcronym: string,
    stateName: string,
    street1: string,
    street2: string,
    zip: string,
  }
)

export const standardizeAddressStorefrontCheckoutApi = ({
  // renamed
  address1,
  street1,
  address2,
  street2,
  stateOrProvince,
  stateName,
  stateOrProvinceCode,
  stateAcronym,
  postalCode,
  zip,
  city = '',
  company = '',
  country = '',
  countryCode = 'US',
  shouldSaveAddress = false,
  customFields = [],
  firstName = '',
  lastName = '',
  phone = '',
  email = '',
  // passed in
  ...rest
}: AddressProps): StandardAddress => ({
  ...rest,
  addressType: 'residential',
  street1: street1 || address1 || '',
  street2: street2 || address2 || '',
  stateName: stateName || stateOrProvince || '',
  stateAcronym: stateAcronym || stateOrProvinceCode || '',
  zip: postalCode || zip || '',
  city,
  shouldSaveAddress,
  company,
  country,
  countryCode: countryCode || countryCode,
  customFields: [...customFields],
  firstName,
  lastName,
  phone,
  email,
})

export default standardizeAddressStorefrontCheckoutApi

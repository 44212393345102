/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
// TODO: Remove eslint disable comments.
// These lint ignores were added to avoid the need of massive refactor of 332 errors
// This happen when changing the rule from warn to error.
// This aims to avoid making the problem worse.
import {
  shipmentsWording,
  orderConfirmationShippingOptionsWording,
  shippingOptionsWording,
} from '@/data/checkout'
import {
  BOPUS_SHIPPING_DESCRIPTION,
  FREE_SHIPPING_DESCRIPTION,
  STANDARD_PLUS_SHIPPING_DESCRIPTION,
} from '@/data/constants'
import { isOrderConfirmationPage } from '@/helpers/checkoutHelpers/isOrderConfirmationPage'
import { formatMoney } from '@/helpers/formatMoney'
import { mapConsignmentIsBopus } from '@/services/Standardizers/checkout/mapConsignmentIsBopus'
import { standardizeAddressStorefrontCheckoutApi } from '@/services/Standardizers/checkout/standardizeAddressStorefrontCheckoutApi'
import type { BCCheckoutConsignmentShippingOptionType, BCCheckoutConsignmentType, BCCheckoutType } from '@/types/BigCommerce/BCCheckout'
import type { StandardCart, StandardShipment } from '@/types/ShopFront/CheckoutStandards'

const isBopusShipment = <T extends { isBopus: boolean }>(shipment: T) => !!(shipment.isBopus)

const isNotBopusShipment = <T extends { isBopus: boolean }>(shipment: T) => (
  !isBopusShipment(shipment)
)

const getNonBopusShipments = <T extends { isBopus: boolean }>(consignments: readonly T[]) => (
  consignments?.filter?.(isNotBopusShipment)
)

type ConsignmentAugmenter = (consignmentInput: {
  consignments: BCCheckoutType['consignments'],
  cart: StandardCart,
}) => StandardShipment[]

const sortNonBopusFirst = <T extends { isBopus: boolean }>(a: T, b: T) => (
  !a.isBopus && b.isBopus ? -1 : 0
)

const augmentAndSortConsigments = (consignments: BCCheckoutType['consignments']) => (
  consignments
    .map(mapConsignmentIsBopus)
    .sort(sortNonBopusFirst)
)

const getNonBopusConsignments = (consignments: BCCheckoutType['consignments']) => (
  getNonBopusShipments(augmentAndSortConsigments(consignments))
)

const hasFurniture = (shipment: readonly { isFurniture: boolean }[]) => (
  !!shipment?.find?.(({ isFurniture }) => !!isFurniture)
)

const getShipmenLineItems = (cart: StandardCart) => (consignment: BCCheckoutConsignmentType & {
  isBopus: boolean,
}) => (
  !!cart?.lineItems && !!consignment?.lineItemIds
    ? [
      ...cart?.lineItems.filter(({ id }) => consignment?.lineItemIds.indexOf(id) >= 0),
    ].map(({ availabilityMessage, actualOverrideMessage, ...rest }) => ({
      availabilityMessage: consignment.isBopus ? actualOverrideMessage : availabilityMessage,
      actualOverrideMessage,
      ...rest,
    }))
    : []
)

const getShipmentWording = (cart: StandardCart) => (consignment: BCCheckoutConsignmentType & {
  isBopus: boolean,
}) => (
  shipmentsWording[
    (consignment.isBopus && 'isBopus')
    || (hasFurniture(getShipmenLineItems(cart)(consignment)) && 'isFurniture')
    || 'accessory'
  ]
)

const optionsWording = (
  isOrderConfirmationPage()
    ? orderConfirmationShippingOptionsWording
    : shippingOptionsWording
)

const isRecommended = (option: { isRecommended?: boolean }) => (
  !!option.isRecommended
)

const getRecomendedShippingOption = ({
  availableShippingOptions,
}: BCCheckoutConsignmentType) => (
  availableShippingOptions.find(isRecommended)
)

const standardizeShippingOption = (consignment: BCCheckoutConsignmentType & {
  isBopus: boolean,
  isFurniture: boolean,
}) => (
  option: BCCheckoutConsignmentShippingOptionType,
) => ({
  ...(
    (consignment.isBopus && optionsWording.isBopus[option.description])
    || (consignment.isFurniture && optionsWording.isFurniture[option.description])
    || optionsWording.accessory[option.description]
  ),
  ...option,
  isRecommended: (
    option.isRecommended || option.description === BOPUS_SHIPPING_DESCRIPTION
  ),
  priceRendered: (
    (String(option.cost) === '0' && 'FREE')
      || (option.isRecommended && formatMoney(option.cost))
      || `+ ${formatMoney(option.cost - (getRecomendedShippingOption(consignment)?.cost || 0))}`
  ),
})

const isBopusShippingOption = (option: { description: string }) => (
  option.description === BOPUS_SHIPPING_DESCRIPTION
)

export const augmentConsignments: ConsignmentAugmenter = ({
  consignments,
  cart,
}) => augmentAndSortConsigments(consignments).map((consignment, consignmentIndex) => {
  const nonBopusShipments = getNonBopusConsignments(consignments)
  const shipment = {
    ...consignment,
    shippingAddress: standardizeAddressStorefrontCheckoutApi(
      { ...consignment.shippingAddress, countryCode: consignment.shippingAddress.countryCode || 'US' },
    ),
    lineItems: getShipmenLineItems(cart)(consignment),
    isFurniture: hasFurniture(getShipmenLineItems(cart)(consignment)),
    ...getShipmentWording(cart)(consignment),
    showShipmentTitleOnCartEditModal: consignmentIndex === 0 && !consignment.isBopus,
    showCartEditTitleOnCartEditModal: consignmentIndex === 0 && !consignment.isBopus,
    shipmentTitle: consignment.isBopus
      ? 'PICK UP IN STORE'
      : `SHIPMENT ${nonBopusShipments?.findIndex?.((s) => s.id === consignment.id) + 1} OF ${nonBopusShipments?.length} - ${getShipmentWording(cart)(consignment).shipmentTitle}`,
  }

  const hasFreShippingOption = (
    options: readonly BCCheckoutConsignmentShippingOptionType[],
  ) => (
    !!options.find(({ description }) => description === FREE_SHIPPING_DESCRIPTION)
  )
  const freeShippingOptions = [FREE_SHIPPING_DESCRIPTION, STANDARD_PLUS_SHIPPING_DESCRIPTION]

  const filterAvailableShippingOptions = (
    options: readonly BCCheckoutConsignmentShippingOptionType[],
  ) => (
    (consignment.isBopus && options.filter(isBopusShippingOption))
    || (
      hasFreShippingOption(options)
      && options.filter((option) => freeShippingOptions.includes(option.description))
    ) || options
  )

  const standardizeShippingOptions = (
    options: readonly BCCheckoutConsignmentShippingOptionType[],
  ) => filterAvailableShippingOptions(options).map(standardizeShippingOption({
    ...consignment,
    isFurniture: shipment.isFurniture,
  }))

  const finalShipment: StandardShipment = {
    ...shipment,
    lineItemIds: [...consignment.lineItemIds],
    selectedShippingOption: (
      shipment.selectedShippingOption
        ? standardizeShippingOptions([shipment.selectedShippingOption])[0]
        : null
    ),
    availableShippingOptions: standardizeShippingOptions(consignment.availableShippingOptions),
  }
  return finalShipment
})

export default augmentConsignments

import type { ModalFields } from '@/types/ShopFront/CheckoutModal'

export const accessoryModal : ModalFields = [
  {
    type: 'title',
    content: 'ACCESSORY SHIPPING DETAILS'
  },
  {
    type: 'paragraph',
    content: [
      'STANDARD SHIPPING',
      'Standard shipping for In Stock items typically deliver in 3-7 business days. Rates will vary based on order total before tax and starts at $5.',
    ]
  },
  {
    type: 'paragraph',
    content: [
      'STANDARD PLUS SHIPPING',
      'Standard Plus shipping for In Stock items typically deliver in 2-3 business days. Rates will vary based on order total before tax and starts at $25. Please note: the Standard Plus Shipping option will only appear for items that qualify.',
    ]
  },
  {
    type: 'table',
    content: {
      title: 'SHIPPING RATES FOR ACCESSORIES',
      rows: [],
    }
  },
  {
    type: 'paragraph',
    content: [
      'QUESTIONS?',
      'Please feel welcome to contact service at (424) 999-4626 between 9am to 5pm CST for any questions relating to your order.',
    ]
  }
]

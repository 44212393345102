/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
// TODO: Remove eslint disable comments.
// These lint ignores were added to avoid the need of massive refactor of 332 errors
// This happen when changing the rule from warn to error.
// This aims to avoid making the problem worse.
import { calculateTotalDiscountFromLineItems } from '@/helpers/checkoutHelpers/calculateTotalDiscountFromLineItems'
import type { StandardCart, StandardOrder } from '@/types/ShopFront/CheckoutStandards'
import type { BCCheckoutType } from '@/types/BigCommerce/BCCheckout'
import { augmentConsignments } from '@/services/Standardizers/checkout/augmentConsignments'
import { standardizeAddressStorefrontCheckoutApi } from '@/services/Standardizers/checkout/standardizeAddressStorefrontCheckoutApi'

const getGrandTotal = ({
  isStoreCreditApplied,
  orderWasFinalized,
  storeCreditDiscount,
  grandTotal,
  discountFromCoupons,
  subtotal,
  taxTotal,
  shippingCostTotal,
}: {
  isStoreCreditApplied: boolean,
  orderWasFinalized: boolean,
  storeCreditDiscount: number,
  grandTotal: number,
  discountFromCoupons: number,
  subtotal: number,
  taxTotal: number,
  shippingCostTotal: number,
}) => (
  !isStoreCreditApplied || orderWasFinalized
    ? grandTotal
    // Subtotal + Shipping & Delivery + Tax - ZGallerieZ Gold - promoCode
    : subtotal + taxTotal + shippingCostTotal - storeCreditDiscount - discountFromCoupons
)

type OrderStandardizer = (orderInput: {
  consignments: readonly any[],
  billingAddress: BCCheckoutType['billingAddress'],
  coupons: readonly any[],
  grandTotal: number,
  taxTotal: number,
  shippingCostBeforeDiscount: number,
  shippingCostTotal: number,
  orderId?: string | number | null,
  status?: string,
  subtotal: number,
  paymentMethod?: {
    mask: string,
    method: string,
  },
  email: string,
  isStoreCreditApplied?: boolean,
  outstandingBalance?: number,
  storeCreditDiscount?: number,
  shippedShieldLineItem?: {
    name: string,
    imageUrl: string,
    listPrice: number,
  },
}, cart: StandardCart) => StandardOrder

export const standardizeOrder: OrderStandardizer = ({
  consignments,
  billingAddress,
  coupons,
  grandTotal,
  taxTotal,
  shippingCostBeforeDiscount,
  shippingCostTotal,
  orderId,
  status = 'INCOMPLETE',
  subtotal,
  paymentMethod = {
    mask: '',
    method: '',
  },
  email,
  isStoreCreditApplied = false,
  outstandingBalance = 0,
  storeCreditDiscount = 0,
  shippedShieldLineItem,
}, cart) => {
  const shipments = augmentConsignments({ consignments, cart })
  const discountFromCoupons = (coupons?.[0]?.discountedAmount || 0)
  const orderWasFinalized = !!orderId

  return {
    totalDiscount: calculateTotalDiscountFromLineItems(cart?.lineItems),
    shipments,
    pickupOnly: shipments.every((consignment) => consignment.isBopus),
    billingAddress: standardizeAddressStorefrontCheckoutApi({
      ...billingAddress,
      email: cart.email,
      countryCode: billingAddress.countryCode || 'US',
    }),
    coupons: [...coupons],
    grandTotal: getGrandTotal({
      isStoreCreditApplied: !!isStoreCreditApplied,
      storeCreditDiscount,
      subtotal,
      grandTotal,
      discountFromCoupons,
      orderWasFinalized,
      taxTotal,
      shippingCostTotal,
    }),
    storeCreditDiscount,
    storeCreditsOverflow: 0,
    completedAt: '',
    subtotal,
    taxTotal,
    cart,
    shippingCostBeforeDiscount,
    shippingCostTotal,
    orderId: String(orderId || ''),
    status,
    paymentMethod,
    customerId: cart.customerId,
    email: email || cart.email,
    isStoreCreditApplied: !!isStoreCreditApplied,
    discountFromCoupons,
    outstandingBalance,
    shippedShieldLineItem,
  }
}

export default standardizeOrder
